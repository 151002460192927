






import { Component } from 'vue-property-decorator'
import Guest from '@/views/Guest.vue'
import Logged from '@/views/Logged.vue'
import Error from '@/views/Error.vue'
import VueStrong from '@/VueStrong'

@Component({
  components: { Logged, Guest, Error }
})
export default class App extends VueStrong {
  get layout() {
    switch (this.$store.state.shopStatus) {
      case 0:
        return 'Guest'
      case 1:
        return 'Logged'
      case -1:
        return 'Error'
      default:
        return 'Error'
    }
  }

  get instance() {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('instance')
  }

  async mounted() {
    try {
      const check = await this.$store.dispatch('check', this.instance)
      console.log('check', check)
    } catch (error) {
      this.$store.dispatch('logout')
    }
  }
}
