import { MutationTree } from 'vuex'
import { IState, SET_USER, UNSET_USER, SHOP_STATUS } from './types'
export default {
  [SET_USER](state: IState, data: IAuthSuccess) {
    const { user, token } = data
    state.user = user
    state.token = token
  },
  [UNSET_USER](state: IState) {
    state.token = null
    state.user = null
  },
  [SHOP_STATUS](state: IState, data: -1 | 1 | 0 | null) {
    state.shopStatus = data
  }
} as MutationTree<IState>
