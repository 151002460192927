import axios from 'axios'

export enum Method {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

class ApiService {
  private _baseUrl = process.env.VUE_APP_API_BASE_URL

  signIn(email: string, password: string): Promise<IAuthSuccess> {
    return this._request(Method.POST, '/api/login', { email, password })
  }

  connect(instance: string, accessToken: string): Promise<any> {
    return this._request(Method.POST, '/storeden/connect', { instance }, { 'x-access-token': accessToken })
  }

  check(instance: string): Promise<any> {
    return this._request(Method.GET, '/storeden/check', { instance })
  }

  signUp(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    newsletter: boolean,
    offers: boolean
  ): Promise<IAuthSuccess> {
    const data = { firstName, lastName, email, password, newsletter, offers }
    return this._request(Method.POST, '/api/customers', data)
  }

  /**
   * @description wrapper for Yakkyofy requests
   *
   * @private
   * @param {Method} method http method. possible values POST, GET, PUT, DELETE
   * @param {string} url endpoint without baseurl
   * @param {object} [data] data sent in body or in query
   * @param {*} [headers] request headers
   *
   * @returns {(Promise<any | IApiList<any>>)}
   */
  private async _request(method: Method, url: string, data?: object, headers?: object): Promise<any> {
    try {
      const body = method === 'GET' ? 'params' : 'data'
      const response = await axios.request({
        method,
        headers,
        [body]: data,
        url: this._baseUrl + url
      })

      if (response.status >= 300 || response.status < 199)
        throw Error('Status code: ' + response.status + ' - ' + JSON.stringify(response.data))

      if (!response.data.success) throw new Error(response.data.error)
      else return response.data
    } catch (error) {
      return Promise.reject(this._parseError(error))
    }
  }

  private _parseError(error: any) {
    const { status, data } = error?.response || {}

    if (data?.error) return error.response?.data?.error
    if (status >= 300 || status < 199) return { code: status, message: 'Request failed with status ' + status }
    return { code: 500, message: 'Ops, an error occurred' }
  }
}

export default new ApiService()
