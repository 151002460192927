








import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class Input extends Vue {
  @Prop({ default: 'text' }) type!: string
  @Prop({ required: true }) value!: string
  @Prop({ required: true }) placeholder!: string
  @Prop() error!: string

  @Emit('onInput')
  onInput(e: any) {
    return e.target.value
  }
}
