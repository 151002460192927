<template>
  <div class="logged-customer">
    <img width="362" src="../assets/error.svg" />
    <h1 class="title">An Error Occured</h1>
    <p>Please check you're accessing this page properly</p>
    <a class="classic-button" href="https://yakkyofy.freshdesk.com/support/home" target="_blank">Get Help</a>
  </div>
</template>
<script>
import { Vue } from 'vue-property-decorator'
export default class Error extends Vue {
  openLink(link) {
    window.open(link, '_blank')
  }
}
</script>
<style scoped>
.logged-customer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
