










import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class Checkbox extends Vue {
  @Prop({ default: 'text' }) label!: string
  @Prop({ required: true }) value!: boolean
  @Prop() error!: string

  @Emit('onClick')
  onClick(e: any) {
    console.log('component', e.target.checked)
    return e.target.checked
  }
}
