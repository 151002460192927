







































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Input from '@/components/Input.vue'
import Checkbox from '@/components/Checkbox.vue'
import Loading from '@/components/Loading.vue'

@Component({ components: { Input, Checkbox, Loading } })
export default class Guest extends Vue {
  tabSelected = 1
  loginForm = {
    email: '',
    password: ''
  }
  registerForm = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    terms: false,
    newsletter: false,
    offers: false
  }
  agreementLinks = {
    privacy: 'https://yakkyofy.com/privacy-cookie-policy/',
    terms: 'https://yakkyofy-website.s3.amazonaws.com/uploads/2018/03/YAKKYO-TERMS-OF-SERVICE.pdf'
  }

  serverError = ''
  loading = true
  serverSuccess = ''

  mounted() {
    this.loading = false
  }

  get loginFormErrors() {
    const { email, password } = this.loginForm
    return {
      email: !email.length || this.validEmail(email) ? null : 'Valid email required.',
      password: !password.length || this.validPassword(password) ? null : 'Password must be at least 4 chracters long'
    }
  }

  get registerFormErrors() {
    const { email, password, firstName, lastName, terms } = this.registerForm

    return {
      email: !email.length || this.validEmail(email) ? null : 'Valid email required.',
      password: !password.length || this.validPassword(password) ? null : 'Password must be at least 4 chracters long',

      firstName: !firstName.length || firstName.length > 2 ? null : 'Invalid first Name',
      lastName: !lastName.length || lastName.length > 2 ? null : 'Invalid last Name',

      terms: terms ? null : 'You need to accept our terms and conditions to register'
    }
  }

  get loginHasErrors() {
    const { email, password } = this.loginFormErrors
    return email || password
  }

  get registerHasErrors() {
    const { email, password, firstName, lastName, terms } = this.registerFormErrors
    return email || password || firstName || lastName || terms
  }

  private validEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  private validPassword(password: string) {
    return password.length > 3
  }

  @Watch('tabSelected')
  private resetNotification() {
    this.serverError = ''
    this.serverSuccess = ''
  }

  async logIn() {
    try {
      this.resetNotification()
      this.loading = true

      await this.$store.dispatch('signIn', this.loginForm)

      const urlParams = new URLSearchParams(window.location.search)
      const instance = urlParams.get('instance')

      if (!instance) this.serverError = 'Internal 400 Error... Try again later or contact us'
      await this.$store.dispatch('connect', instance)
      await this.$store.dispatch('check', instance)
    } catch (error) {
      this.serverError = error.message || error
    }
    this.loading = false
  }

  async register() {
    try {
      this.resetNotification()
      this.loading = true

      await this.$store.dispatch('signUp', this.registerForm)

      this.tabSelected = 1
    } catch (error) {
      this.serverError = error.message || error
    }
    this.loading = false
  }
}
