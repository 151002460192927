import { ActionTree } from 'vuex'
import apiClient from '../utils/api'
import { IState, SET_USER, SHOP_STATUS, UNSET_USER } from './types'

export default {
  async check({ commit }, instance: string): Promise<any> {
    try {
      const response = await apiClient.check(instance)
      commit(SHOP_STATUS, response.code)
      return response
    } catch (error) {
      console.log('error on check', error)
      return Promise.reject(error)
    }
  },
  async signUp({ commit }, data: any): Promise<IAuthSuccess> {
    try {
      const { email, password, firstName, lastName, newsletter, offers } = data
      const response = await apiClient.signUp(firstName, lastName, email, password, newsletter, offers)

      commit(SET_USER, response)
      return response
    } catch (error) {
      console.log('error on signUp', error)
      return Promise.reject(error)
    }
  },
  async signIn({ commit }, data: any): Promise<IAuthSuccess> {
    try {
      const { email, password } = data
      const response = await apiClient.signIn(email, password)

      commit(SET_USER, response)
      return response
    } catch (error) {
      console.log('error on signIn', error)
      return Promise.reject(error)
    }
  },
  async connect({ commit, state }, instance: string): Promise<any> {
    try {
      if (!state.token) throw new Error('please, login first')

      const response = await apiClient.connect(instance, state.token)
      commit(SHOP_STATUS, response.success)
      return response
    } catch (error) {
      console.log('error on connect', error)
      commit(UNSET_USER)
      commit(SHOP_STATUS, false)
      return Promise.reject(error)
    }
  },
  logout({ commit }) {
    commit(UNSET_USER)
  }
} as ActionTree<IState, any>
